<script>
import { postProspectusSave } from '../../../api/manuscript'
import { effectiveLength } from '@/utils'
import _ from 'lodash'
export default {
    name: "PlanDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        planData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
      return {
        titleData: [
          {
              label: '请以不超过100字简介你在主要工作项目上曾担当的角色、你工作期间曾服务的知名机构/单位或你对有关工作项目及机构的重大贡献：',
              key: 'career_achievement',
              remark_key: 'career_achievement_remark',
          },
          {
              label: '请以不超过100字简介你的学业成绩或曾取得的奖项：',
              key: 'academic_achievement',
              remark_key: 'academic_achievement_remark',
          },
          {
              label: '请以不超过100字简介你在所属的专业界别内曾获取由国家或国际认可/著名的团体颁授的专业资格，及曾取得的奖项：',
              key: 'qualifications_and_awards',
              remark_key: 'prestigious_awards_remark',
          },
          {
            label: '你的专业技能或人才清单内相关专业的成就：',
              key: 'professional_skills',
              remark_key: 'professional_skills_remark',
          },
          {
              label: '请以不超过100字简介你的人才清单内相关专业、专业技能或成就（例如你拥有的专利或发明、你在学术研究的领导角色、或你在专业领域获得的国际或地区奖项）如何对香港经济或社会作出重大贡献：',
              key: 'visa_contribution',
              remark_key: 'visa_contribution_remark',
          },

          {
              label: '请以不超过100字简介你与本地商业或学术机构联系及合作的详情：',
              key: 'plan_detail',
              remark_key: 'development_plan_remark',
          },

          {
              label: '你认为对评核你的申请非常重要的其他资料：',
              key: 'other',
              remark_key: 'remark',
          },
        ]
      }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible
            },
            set(val) {
                this.$emit("update:visible", val)
            }
        },
        dialogData: {
            get() {
                return this.planData;
            },
            set(val) {
                this.$emit("update:planData", val)
            }
        }
    },
    methods: {
        async confirm() {
            await postProspectusSave({ ...this.dialogData })
            this.$message.success('保存成功')
            this.dialogVisible = false
        },
        effectiveLength,
        // 静默保存用户填写的内容
        silentSave: _.debounce(function() {
            postProspectusSave({ ...this.dialogData })
        }, 2000, {
            trailing: true,
        })

    }
}
</script>

<template>
    <!--  赴港计划书弹窗  -->
    <el-dialog title="赴港计划书" center lock-scroll width="60%" top="10vh" custom-class="fg-dialog" :visible.sync="dialogVisible">
        <div class="fg-content">
            <div class="title">应香港入境处最新要求，赴港计划书格式、内容作出调整，共分为七部分，每部分不得超过100字(不含标点)。其中“赴港具体计划”仅代表个人预期规划，无须严格执行。</div>
            <div v-for="(item, idx) in titleData" :key="item.title" class="item">
                <div class="item-title">
                    {{ idx + 1 }}、
                    {{ item.label }}
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData[item.key]"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData[item.key]) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="canEdit" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirm">保 存</el-button>
        </div>
    </el-dialog>
</template>

<style>
.fg-dialog {
    height: 80%;
    border-radius: 8px;
}
.fg-dialog .fg-content {
    height: 100%;
    overflow-y: scroll;
}
.fg-dialog .fg-content::-webkit-scrollbar{
    display:none;
}
.fg-dialog .el-dialog__body {
    height: 85%;
}
.title {
    font-size: 14px;
    font-weight: bold;
}
.item-title {
    margin: 20px 0;
}
.item-content {
    position: relative;
}
.word-num {
    position: absolute;
    right: 10px;
    bottom: 4px;
    line-height: 14px;
    font-size: 12px;
    color: #909399;
}
</style>
